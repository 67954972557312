:root{
    --bs-orange: #1452fd;
}
.tim-row {
    margin-bottom: 20px;
}

.tim-white-buttons {
    background-color: #777777;
}

.typography-line {
    padding-left: 25%;
    margin-bottom: 35px;
    position: relative;
    display: block;
    width: 100%;
}

.typography-line span {
    bottom: 10px;
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    left: 0;
    margin-left: 20px;
    position: absolute;
    width: 260px;
    text-transform: none;
}

.tim-row {
    padding-top: 60px;
}

.tim-row h3 {
    margin-top: 0;
    font-weight: 400;
    line-height: 1.2;
}

.switch {
    margin-right: 20px;
}

#navbar-full .navbar {
    border-radius: 0 !important;
    margin-bottom: 15px;
    z-index: 2;
}

#menu-dropdown .navbar {
    border-radius: 3px;
}

#pagination-row .pagination-container {
    height: 100%;
    max-height: 100%;
    align-items: center;
}

#icons-row i.now-ui-icons {
    font-size: 30px;
}

.space {
    height: 130px;
    display: block;
}

.space-110 {
    height: 110px;
    display: block;
}

.space-50 {
    height: 50px;
    display: block;
}

.space-70 {
    height: 70px;
    display: block;
}

.navigation-example .img-src {
    background-attachment: scroll;
}

.navigation-example {
    background-position: center center;
    background-size: cover;
    margin-top: 0;
    min-height: 740px;
    height: 100%;
}

#notifications {
    background-color: #FFFFFF;
    display: block;
    width: 100%;
    position: relative;
}

.tim-note {
    text-transform: capitalize;
}

.space-100 {
    height: 100px;
    display: block;
    width: 100%;
}

.be-social {
    padding-bottom: 20px;
    /*     border-bottom: 1px solid #aaa; */
    margin: 0 auto 40px;
}

.txt-white {
    color: #FFFFFF;
}

.txt-gray {
    color: #ddd !important;
}

.parallax {
    width: 100%;
    height: 570px;
    display: block;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.logo-container .logo {
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #333333;
    width: 50px;
    float: left;
}

.logo-container .brand {
    font-size: 16px;
    color: #FFFFFF;
    line-height: 18px;
    float: left;
    margin-left: 10px;
    margin-top: 7px;
    width: 70px;
    height: 40px;
    text-align: left;
}

.logo-container .brand-material {
    font-size: 18px;
    margin-top: 15px;
    height: 25px;
    width: auto;
}

.logo-container .logo img {
    width: 100%;
}

.navbar-small .logo-container .brand {
    color: #333333;
}

.fixed-section {
    top: 90px;
    max-height: 80vh;
    overflow: scroll;
    position: sticky;
}

.fixed-section ul {
    padding: 0;
}

.fixed-section ul li {
    list-style: none;
}

.fixed-section li a {
    font-size: 14px;
    padding: 2px;
    display: block;
    color: #666666;
}

.fixed-section li a.active {
    color: #00bbff;
}

.fixed-section.float {
    position: fixed;
    top: 100px;
    width: 200px;
    margin-top: 0;
}

.parallax .parallax-image {
    width: 100%;
    overflow: hidden;
    position: absolute;
}

.parallax .parallax-image img {
    width: 100%;
}

@media (max-width: 768px) {
    .parallax .parallax-image {
        width: 100%;
        height: 640px;
        overflow: hidden;
    }
    .parallax .parallax-image img {
        height: 100%;
        width: auto;
    }
}


/*.separator{
    content: "Separator";
    color: #FFFFFF;
    display: block;
    width: 100%;
    padding: 20px;
}
.separator-line{
    background-color: #EEE;
    height: 1px;
    width: 100%;
    display: block;
}
.separator.separator-gray{
    background-color: #EEEEEE;
}*/

.social-buttons-demo .btn {
    margin-right: 5px;
    margin-bottom: 7px;
}

.img-container {
    width: 100%;
    overflow: hidden;
}

.img-container img {
    width: 100%;
}

.lightbox img {
    width: 100%;
}

.lightbox .modal-content {
    overflow: hidden;
}

.lightbox .modal-body {
    padding: 0;
}

@media screen and (min-width: 991px) {
    .lightbox .modal-dialog {
        width: 960px;
    }
}

@media (max-width: 991px) {
    .fixed-section.affix {
        position: relative;
        margin-bottom: 100px;
    }
}

@media (max-width: 768px) {
    .btn,
    .btn-morphing {
        margin-bottom: 10px;
    }
    .parallax .motto {
        top: 170px;
        margin-top: 0;
        font-size: 60px;
        width: 270px;
    }
}


/*       Loading dots  */


/*      transitions */

.presentation .front,
.presentation .front:after,
.presentation .front .btn,
.logo-container .logo,
.logo-container .brand {
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}

#images h4 {
    margin-bottom: 30px;
}

#javascriptComponents {
    padding-bottom: 0;
}


/*      layer animation          */

.layers-container {
    display: block;
    margin-top: 50px;
    position: relative;
}

.layers-container img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    text-align: center;
}

.animate {
    transition: 1.5s ease-in-out;
    -moz-transition: 1.5s ease-in-out;
    -webkit-transition: 1.5s ease-in-out;
}

.navbar-default.navbar-small .logo-container .brand {
    color: #333333;
}

.navbar-transparent.navbar-small .logo-container .brand {
    color: #FFFFFF;
}

.navbar-default.navbar-small .logo-container .brand {
    color: #333333;
}

.sharing-area {
    margin-top: 80px;
}

.sharing-area .btn {
    margin: 15px 4px 0;
}

.section-thin,
.section-notifications {
    padding: 0;
}

.section-navbars {
    padding-top: 0;
}

#navbar .navbar {
    margin-bottom: 20px;
}

#navbar .navbar-toggler,
#menu-dropdown .navbar-toggler {
    pointer-events: none;
}

.section-tabs {
    background: #EEEEEE;
}

.section-pagination {
    padding-bottom: 0;
}

.section-download {
    padding-top: 130px;
}

.section-download .description {
    margin-bottom: 60px;
}

.section-download h4 {
    margin-bottom: 25px;
}

.section-examples a {
    text-decoration: none;
}

.section-examples a+a {
    margin-top: 30px;
}

.section-examples h5 {
    margin-top: 30px;
}

.components-page .wrapper>.header,
.tutorial-page .wrapper>.header {
    height: 500px;
    padding-top: 128px;
    background-size: cover;
    background-position: center center;
}

.components-page .title,
.tutorial-page .title {
    color: #FFFFFF;
}

.brand .h1-seo {
    font-size: 2.8em;
    text-transform: uppercase;
    font-weight: 400;
}

.brand .n-logo {
    max-width: 100px;
    margin-bottom: 40px;
}

.invision-logo {
    max-width: 70px;
    top: -2px;
    position: relative;
}

.creative-tim-logo {
    max-width: 140px;
    top: -2px;
    position: relative;
}

.section-javascript .title {
    margin-bottom: 0;
}

.navbar .switch-background {
    display: block;
}

.navbar-transparent .switch-background {
    display: none;
}

.section-signup .col .btn {
    margin-top: 30px;
}

#buttons-row .btn {
    margin-bottom: 10px;
}

.section-basic {
    padding-top: 0;
}

.section-images {
    padding-bottom: 0;
}

@media screen and (max-width: 991px) {
    .section-navbars .navbar-collapse {
        display: none !important;
    }
}


.lead {
    font-size: 0.5 rem;
    font-weight: 400;
    line-height: 1.625;
  }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1,
.h1 {
  font-size: calc(1.425rem + 2.1vw);
}

@media (min-width: 1200px) {

  h1,
  .h1 {
    font-size: 3rem;
  }
}

h2,
.h2 {
  font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {

  h2,
  .h2 {
    font-size: 2.25rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {

  h3,
  .h3 {
    font-size: 1.875rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {

  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}


.bg-primary {
    background-color:#072b44 !important;
    
  }

.blue{
    filter: invert(43%) sepia(82%) saturate(760%) hue-rotate(155deg) brightness(99%) contrast(102%);
}

.m-0 {
    margin: 0 !important;
  }
  
  .m-1 {
    margin: 0.25rem !important;
  }
  
  .m-2 {
    margin: 0.5rem !important;
  }
  
  .m-3 {
    margin: 1rem !important;
  }
  
  .m-4 {
    margin: 1.5rem !important;
  }
  
  .m-5 {
    margin: 3rem !important;
  }
  
  .m-6 {
    margin: 4rem !important;
  }
  
  .m-7 {
    margin: 6rem !important;
  }
  
  .m-8 {
    margin: 8rem !important;
  }
  
  .m-9 {
    margin: 10rem !important;
  }
  
  .m-10 {
    margin: 12rem !important;
  }
  
  .m-11 {
    margin: 14rem !important;
  }
  
  .m-12 {
    margin: 16rem !important;
  }
  
  .m-auto {
    margin: auto !important;
  }
  
  .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  
  .mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  
  .mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  
  .mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  
  .mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  
  .mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  
  .mx-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  
  .mx-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  
  .mx-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  
  .mx-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  
  .mx-10 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
  }
  
  .mx-11 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  
  .mx-12 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }
  
  .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  
  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  
  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  
  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  
  .my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  
  .my-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  
  .my-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  
  .my-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  
  .my-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  
  .my-10 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }
  
  .my-11 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  
  .my-12 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }
  
  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  
  .mt-0 {
    margin-top: 0 !important;
  }
  
  .mt-1 {
    margin-top: 0.25rem !important;
  }
  
  .mt-2 {
    margin-top: 0.5rem !important;
  }
  
  .mt-3 {
    margin-top: 1rem !important;
  }
  
  .mt-4 {
    margin-top: 1.5rem !important;
  }
  
  .mt-5 {
    margin-top: 3rem !important;
  }
  
  .mt-6 {
    margin-top: 4rem !important;
  }
  
  .mt-7 {
    margin-top: 6rem !important;
  }
  
  .mt-8 {
    margin-top: 8rem !important;
  }
  
  .mt-9 {
    margin-top: 10rem !important;
  }
  
  .mt-10 {
    margin-top: 12rem !important;
  }
  
  .mt-11 {
    margin-top: 14rem !important;
  }
  
  .mt-12 {
    margin-top: 16rem !important;
  }
  
  .mt-auto {
    margin-top: auto !important;
  }
  
  .me-0 {
    margin-right: 0 !important;
  }
  
  .me-1 {
    margin-right: 0.25rem !important;
  }
  
  .me-2 {
    margin-right: 0.5rem !important;
  }
  
  .me-3 {
    margin-right: 1rem !important;
  }
  
  .me-4 {
    margin-right: 1.5rem !important;
  }
  
  .me-5 {
    margin-right: 3rem !important;
  }
  
  .me-6 {
    margin-right: 4rem !important;
  }
  
  .me-7 {
    margin-right: 6rem !important;
  }
  
  .me-8 {
    margin-right: 8rem !important;
  }
  
  .me-9 {
    margin-right: 10rem !important;
  }
  
  .me-10 {
    margin-right: 12rem !important;
  }
  
  .me-11 {
    margin-right: 14rem !important;
  }
  
  .me-12 {
    margin-right: 16rem !important;
  }
  
  .me-auto {
    margin-right: auto !important;
  }
  
  .mb-0 {
    margin-bottom: 0 !important;
  }
  
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
  
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
  
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
  
  .mb-5 {
    margin-bottom: 3rem !important;
  }
  
  .mb-6 {
    margin-bottom: 4rem !important;
  }
  
  .mb-7 {
    margin-bottom: 6rem !important;
  }
  
  .mb-8 {
    margin-bottom: 8rem !important;
  }
  
  .mb-9 {
    margin-bottom: 10rem !important;
  }
  
  .mb-10 {
    margin-bottom: 12rem !important;
  }
  
  .mb-11 {
    margin-bottom: 14rem !important;
  }
  
  .mb-12 {
    margin-bottom: 16rem !important;
  }
  
  .mb-auto {
    margin-bottom: auto !important;
  }
  
  .ms-0 {
    margin-left: 0 !important;
  }
  
  .ms-1 {
    margin-left: 0.25rem !important;
  }
  
  .ms-2 {
    margin-left: 0.5rem !important;
  }
  
  .ms-3 {
    margin-left: 1rem !important;
  }
  
  .ms-4 {
    margin-left: 1.5rem !important;
  }
  
  .ms-5 {
    margin-left: 3rem !important;
  }
  
  .ms-6 {
    margin-left: 4rem !important;
  }
  
  .ms-7 {
    margin-left: 6rem !important;
  }
  
  .ms-8 {
    margin-left: 8rem !important;
  }
  
  .ms-9 {
    margin-left: 10rem !important;
  }
  
  .ms-10 {
    margin-left: 12rem !important;
  }
  
  .ms-11 {
    margin-left: 14rem !important;
  }
  
  .ms-12 {
    margin-left: 16rem !important;
  }
  
  .ms-auto {
    margin-left: auto !important;
  }
  
  .m-n1 {
    margin: -0.25rem !important;
  }
  
  .m-n2 {
    margin: -0.5rem !important;
  }
  
  .m-n3 {
    margin: -1rem !important;
  }
  
  .m-n4 {
    margin: -1.5rem !important;
  }
  
  .m-n5 {
    margin: -3rem !important;
  }
  
  .m-n6 {
    margin: -4rem !important;
  }
  
  .m-n7 {
    margin: -6rem !important;
  }
  
  .m-n8 {
    margin: -8rem !important;
  }
  
  .m-n9 {
    margin: -10rem !important;
  }
  
  .m-n10 {
    margin: -12rem !important;
  }
  
  .m-n11 {
    margin: -14rem !important;
  }
  
  .m-n12 {
    margin: -16rem !important;
  }
  
  .mx-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  
  .mx-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  
  .mx-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  
  .mx-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  
  .mx-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  
  .mx-n6 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  
  .mx-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  
  .mx-n8 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  
  .mx-n9 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  
  .mx-n10 {
    margin-right: -12rem !important;
    margin-left: -12rem !important;
  }
  
  .mx-n11 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }
  
  .mx-n12 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }
  
  .my-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  
  .my-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  
  .my-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  
  .my-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  
  .my-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  
  .my-n6 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  
  .my-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  
  .my-n8 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  
  .my-n9 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  
  .my-n10 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }
  
  .my-n11 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }
  
  .my-n12 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }
  
  .mt-n1 {
    margin-top: -0.25rem !important;
  }
  
  .mt-n2 {
    margin-top: -0.5rem !important;
  }
  
  .mt-n3 {
    margin-top: -1rem !important;
  }
  
  .mt-n4 {
    margin-top: -1.5rem !important;
  }
  
  .mt-n5 {
    margin-top: -3rem !important;
  }
  
  .mt-n6 {
    margin-top: -4rem !important;
  }
  
  .mt-n7 {
    margin-top: -6rem !important;
  }
  
  .mt-n8 {
    margin-top: -8rem !important;
  }
  
  .mt-n9 {
    margin-top: -10rem !important;
  }
  
  .mt-n10 {
    margin-top: -12rem !important;
  }
  
  .mt-n11 {
    margin-top: -14rem !important;
  }
  
  .mt-n12 {
    margin-top: -16rem !important;
  }
  
  .me-n1 {
    margin-right: -0.25rem !important;
  }
  
  .me-n2 {
    margin-right: -0.5rem !important;
  }
  
  .me-n3 {
    margin-right: -1rem !important;
  }
  
  .me-n4 {
    margin-right: -1.5rem !important;
  }
  
  .me-n5 {
    margin-right: -3rem !important;
  }
  
  .me-n6 {
    margin-right: -4rem !important;
  }
  
  .me-n7 {
    margin-right: -6rem !important;
  }
  
  .me-n8 {
    margin-right: -8rem !important;
  }
  
  .me-n9 {
    margin-right: -10rem !important;
  }
  
  .me-n10 {
    margin-right: -12rem !important;
  }
  
  .me-n11 {
    margin-right: -14rem !important;
  }
  
  .me-n12 {
    margin-right: -16rem !important;
  }
  
  .mb-n1 {
    margin-bottom: -0.25rem !important;
  }
  
  .mb-n2 {
    margin-bottom: -0.5rem !important;
  }
  
  .mb-n3 {
    margin-bottom: -1rem !important;
  }
  
  .mb-n4 {
    margin-bottom: -1.5rem !important;
  }
  
  .mb-n5 {
    margin-bottom: -3rem !important;
  }
  
  .mb-n6 {
    margin-bottom: -4rem !important;
  }
  
  .mb-n7 {
    margin-bottom: -6rem !important;
  }
  
  .mb-n8 {
    margin-bottom: -8rem !important;
  }
  
  .mb-n9 {
    margin-bottom: -10rem !important;
  }
  
  .mb-n10 {
    margin-bottom: -12rem !important;
  }
  
  .mb-n11 {
    margin-bottom: -14rem !important;
  }
  
  .mb-n12 {
    margin-bottom: -16rem !important;
  }
  
  .ms-n1 {
    margin-left: -0.25rem !important;
  }
  
  .ms-n2 {
    margin-left: -0.5rem !important;
  }
  
  .ms-n3 {
    margin-left: -1rem !important;
  }
  
  .ms-n4 {
    margin-left: -1.5rem !important;
  }
  
  .ms-n5 {
    margin-left: -3rem !important;
  }
  
  .ms-n6 {
    margin-left: -4rem !important;
  }
  
  .ms-n7 {
    margin-left: -6rem !important;
  }
  
  .ms-n8 {
    margin-left: -8rem !important;
  }
  
  .ms-n9 {
    margin-left: -10rem !important;
  }
  
  .ms-n10 {
    margin-left: -12rem !important;
  }
  
  .ms-n11 {
    margin-left: -14rem !important;
  }
  
  .ms-n12 {
    margin-left: -16rem !important;
  }
  
  .p-0 {
    padding: 0 !important;
  }
  
  .p-1 {
    padding: 0.25rem !important;
  }
  
  .p-2 {
    padding: 0.5rem !important;
  }
  
  .p-3 {
    padding: 1rem !important;
  }
  
  .p-4 {
    padding: 1.5rem !important;
  }
  
  .p-5 {
    padding: 3rem !important;
  }
  
  .p-6 {
    padding: 4rem !important;
  }
  
  .p-7 {
    padding: 6rem !important;
  }
  
  .p-8 {
    padding: 8rem !important;
  }
  
  .p-9 {
    padding: 10rem !important;
  }
  
  .p-10 {
    padding: 12rem !important;
  }
  
  .p-11 {
    padding: 14rem !important;
  }
  
  .p-12 {
    padding: 16rem !important;
  }
  
  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  
  .px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  
  .px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  
  .px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  
  .px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  
  .px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  
  .px-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  
  .px-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  
  .px-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  
  .px-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  
  .px-10 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }
  
  .px-11 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  
  .px-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }
  
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  
  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  
  .py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  
  .py-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  
  .py-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  
  .py-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  
  .py-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  
  .py-10 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  
  .py-11 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  
  .py-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  
  .pt-0 {
    padding-top: 0 !important;
  }
  
  .pt-1 {
    padding-top: 0.25rem !important;
  }
  
  .pt-2 {
    padding-top: 0.5rem !important;
  }
  
  .pt-3 {
    padding-top: 1rem !important;
  }
  
  .pt-4 {
    padding-top: 1.5rem !important;
  }
  
  .pt-5 {
    padding-top: 3rem !important;
  }
  
  .pt-6 {
    padding-top: 4rem !important;
  }
  
  .pt-7 {
    padding-top: 6rem !important;
  }
  
  .pt-8 {
    padding-top: 8rem !important;
  }
  
  .pt-9 {
    padding-top: 10rem !important;
  }
  
  .pt-10 {
    padding-top: 12rem !important;
  }
  
  .pt-11 {
    padding-top: 14rem !important;
  }
  
  .pt-12 {
    padding-top: 16rem !important;
  }
  
  .pe-0 {
    padding-right: 0 !important;
  }
  
  .pe-1 {
    padding-right: 0.25rem !important;
  }
  
  .pe-2 {
    padding-right: 0.5rem !important;
  }
  
  .pe-3 {
    padding-right: 1rem !important;
  }
  
  .pe-4 {
    padding-right: 1.5rem !important;
  }
  
  .pe-5 {
    padding-right: 3rem !important;
  }
  
  .pe-6 {
    padding-right: 4rem !important;
  }
  
  .pe-7 {
    padding-right: 6rem !important;
  }
  
  .pe-8 {
    padding-right: 8rem !important;
  }
  
  .pe-9 {
    padding-right: 10rem !important;
  }
  
  .pe-10 {
    padding-right: 12rem !important;
  }
  
  .pe-11 {
    padding-right: 14rem !important;
  }
  
  .pe-12 {
    padding-right: 16rem !important;
  }
  
  .pb-0 {
    padding-bottom: 0 !important;
  }
  
  .pb-1 {
    padding-bottom: 0.25rem !important;
  }
  
  .pb-2 {
    padding-bottom: 0.5rem !important;
  }
  
  .pb-3 {
    padding-bottom: 1rem !important;
  }
  
  .pb-4 {
    padding-bottom: 1.5rem !important;
  }
  
  .pb-5 {
    padding-bottom: 3rem !important;
  }
  
  .pb-6 {
    padding-bottom: 4rem !important;
  }
  
  .pb-7 {
    padding-bottom: 6rem !important;
  }
  
  .pb-8 {
    padding-bottom: 8rem !important;
  }
  
  .pb-9 {
    padding-bottom: 10rem !important;
  }
  
  .pb-10 {
    padding-bottom: 12rem !important;
  }
  
  .pb-11 {
    padding-bottom: 14rem !important;
  }
  
  .pb-12 {
    padding-bottom: 16rem !important;
  }
  
  .ps-0 {
    padding-left: 0 !important;
  }
  
  .ps-1 {
    padding-left: 0.25rem !important;
  }
  
  .ps-2 {
    padding-left: 0.5rem !important;
  }
  
  .ps-3 {
    padding-left: 1rem !important;
  }
  
  .ps-4 {
    padding-left: 1.5rem !important;
  }
  
  .ps-5 {
    padding-left: 3rem !important;
  }
  
  .ps-6 {
    padding-left: 4rem !important;
  }
  
  .ps-7 {
    padding-left: 6rem !important;
  }
  
  .ps-8 {
    padding-left: 8rem !important;
  }
  
  .ps-9 {
    padding-left: 10rem !important;
  }
  
  .ps-10 {
    padding-left: 12rem !important;
  }
  
  .ps-11 {
    padding-left: 14rem !important;
  }
  
  .ps-12 {
    padding-left: 16rem !important;
  }
  
  .gap-0 {
    gap: 0 !important;
  }
  
  .gap-1 {
    gap: 0.25rem !important;
  }
  
  .gap-2 {
    gap: 0.5rem !important;
  }
  
  .gap-3 {
    gap: 1rem !important;
  }
  
  .gap-4 {
    gap: 1.5rem !important;
  }
  
  .gap-5 {
    gap: 3rem !important;
  }
  
  .gap-6 {
    gap: 4rem !important;
  }
  
  .gap-7 {
    gap: 6rem !important;
  }
  
  .gap-8 {
    gap: 8rem !important;
  }
  
  .gap-9 {
    gap: 10rem !important;
  }
  
  .gap-10 {
    gap: 12rem !important;
  }
  
  .gap-11 {
    gap: 14rem !important;
  }
  
  .gap-12 {
    gap: 16rem !important;
  }
  

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .p-xxl-6 {
    padding: 4rem !important;
  }

  .p-xxl-7 {
    padding: 6rem !important;
  }

  .p-xxl-8 {
    padding: 8rem !important;
  }

  .p-xxl-9 {
    padding: 10rem !important;
  }

  .p-xxl-10 {
    padding: 12rem !important;
  }

  .p-xxl-11 {
    padding: 14rem !important;
  }

  .p-xxl-12 {
    padding: 16rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }

  .px-xxl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-xxl-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-xxl-10 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
  }

  .px-xxl-11 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .px-xxl-12 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xxl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xxl-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-xxl-10 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .py-xxl-11 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-xxl-12 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pt-xxl-6 {
    padding-top: 4rem !important;
  }

  .pt-xxl-7 {
    padding-top: 6rem !important;
  }

  .pt-xxl-8 {
    padding-top: 8rem !important;
  }

  .pt-xxl-9 {
    padding-top: 10rem !important;
  }

  .pt-xxl-10 {
    padding-top: 12rem !important;
  }

  .pt-xxl-11 {
    padding-top: 14rem !important;
  }

  .pt-xxl-12 {
    padding-top: 16rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pe-xxl-6 {
    padding-right: 4rem !important;
  }

  .pe-xxl-7 {
    padding-right: 6rem !important;
  }

  .pe-xxl-8 {
    padding-right: 8rem !important;
  }

  .pe-xxl-9 {
    padding-right: 10rem !important;
  }

  .pe-xxl-10 {
    padding-right: 12rem !important;
  }

  .pe-xxl-11 {
    padding-right: 14rem !important;
  }

  .pe-xxl-12 {
    padding-right: 16rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 6rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 8rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 10rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 12rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 14rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 16rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .ps-xxl-6 {
    padding-left: 4rem !important;
  }

  .ps-xxl-7 {
    padding-left: 6rem !important;
  }

  .ps-xxl-8 {
    padding-left: 8rem !important;
  }

  .ps-xxl-9 {
    padding-left: 10rem !important;
  }

  .ps-xxl-10 {
    padding-left: 12rem !important;
  }

  .ps-xxl-11 {
    padding-left: 14rem !important;
  }

  .ps-xxl-12 {
    padding-left: 16rem !important;
  }



  .input-group.input-group-dynamic.is-focused .form-label,
  .input-group.input-group-dynamic.is-filled .form-label,
  .input-group.input-group-static.is-focused .form-label,
  .input-group.input-group-static.is-filled .form-label {
    font-size: 0.6875rem !important;
  }
  
  .input-group.input-group-dynamic.is-focused .form-label,
  .input-group.input-group-static.is-focused .form-label {
    top: -0.7rem;
  }

  .input-group.input-group-dynamic.is-filled.is-focused .form-label,
.input-group.input-group-dynamic.is-filled .form-label,
.input-group.input-group-static.is-filled.is-focused .form-label,
.input-group.input-group-static.is-filled .form-label {
  top: -1rem;
}

.input-group.input-group-outline .form-label {
    display: flex;
    line-height: 3.925 !important;
    top: -0.375rem;
    margin-bottom: 0;
  }
  
  .input-group.input-group-outline .form-label:before {
    content: "";
    margin-right: 4px;
    border-left: solid 1px transparent;
    border-radius: 4px 0;
  }
  
  .input-group.input-group-outline .form-label:after {
    content: "";
    flex-grow: 1;
    margin-left: 4px;
    border-right: solid 1px transparent;
    border-radius: 0 5px;
  }
  
  .input-group.input-group-outline .form-label:before,
  .input-group.input-group-outline .form-label:after {
    content: "";
    border-top: solid 1px;
    border-top-color: #d2d6da;
    pointer-events: none;
    margin-top: 0.375rem;
    box-sizing: border-box;
    display: block;
    height: 0.5rem;
    width: 0.625rem;
    border-width: 1px 0 0;
    border-color: transparent;
  }
  
  .input-group.input-group-outline.is-focused .form-label+.form-control,
  .input-group.input-group-outline.is-filled .form-label+.form-control {
    border-color: #220d14 !important;
  }
  
  .input-group.input-group-outline.is-focused .form-label,
  .input-group.input-group-outline.is-filled .form-label {
    width: 100%;
    height: 100%;
    font-size: 0.6875rem !important;
    color: #220d14;
    display: flex;
    line-height: 1.25 !important;
  }
  
  .input-group.input-group-outline.is-focused .form-label:before,
  .input-group.input-group-outline.is-focused .form-label:after,
  .input-group.input-group-outline.is-filled .form-label:before,
  .input-group.input-group-outline.is-filled .form-label:after {
    opacity: 0;
  }
  
  .input-group.input-group-outline.is-focused .form-label:before,
  .input-group.input-group-outline.is-focused .form-label:after,
  .input-group.input-group-outline.is-filled .form-label:before,
  .input-group.input-group-outline.is-filled .form-label:after {
    border-top-color: #220d14;
    box-shadow: inset 0 1px #220d14;
  }
  .input-group.input-group-outline.is-valid.is-focused .form-label+.form-control,
  .input-group.input-group-outline.is-valid.is-filled .form-label+.form-control {
    border-color: #4CAF50 !important;
    box-shadow: inset 1px 0 #4CAF50, inset -1px 0 #4CAF50, inset 0 -1px #4CAF50;
    border-top-color: transparent !important;
  }
  
  .input-group.input-group-outline.is-valid.is-focused .form-label,
  .input-group.input-group-outline.is-valid.is-filled .form-label {
    color: #4CAF50;
  }
  
  .input-group.input-group-outline.is-valid.is-focused .form-label:before,
  .input-group.input-group-outline.is-valid.is-focused .form-label:after,
  .input-group.input-group-outline.is-valid.is-filled .form-label:before,
  .input-group.input-group-outline.is-valid.is-filled .form-label:after {
    border-top-color: #4CAF50;
    box-shadow: inset 0 1px #4CAF50;
  }
  
  .input-group.input-group-outline.is-invalid .form-control {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd5c70' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fd5c70' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 1rem 1rem;
  }
  
  .input-group.input-group-outline.is-invalid.is-focused .form-label+.form-control,
  .input-group.input-group-outline.is-invalid.is-filled .form-label+.form-control {
    border-color: #220d14 !important;
    box-shadow: inset 1px 0 #220d14, inset -1px 0 #220d14, inset 0 -1px #220d14;
    border-top-color: transparent !important;
  }
  
  .input-group.input-group-outline.is-invalid.is-focused .form-label,
  .input-group.input-group-outline.is-invalid.is-filled .form-label {
    color: #220d14;
  }
  
  .input-group.input-group-outline.is-invalid.is-focused .form-label:before,
  .input-group.input-group-outline.is-invalid.is-focused .form-label:after,
  .input-group.input-group-outline.is-invalid.is-filled .form-label:before,
  .input-group.input-group-outline.is-invalid.is-filled .form-label:after {
    border-top-color: #220d14;
    box-shadow: inset 0 1px #220d14;
  }
  
  .input-group.input-group-outline.input-group-sm .form-label,
  .input-group.input-group-outline.input-group-sm label,
  .input-group.input-group-dynamic.input-group-sm .form-label,
  .input-group.input-group-dynamic.input-group-sm label,
  .input-group.input-group-static.input-group-sm .form-label,
  .input-group.input-group-static.input-group-sm label {
    font-size: 0.75rem;
  }
  
  .input-group.input-group-outline.input-group-lg .form-label,
  .input-group.input-group-outline.input-group-lg label,
  .input-group.input-group-dynamic.input-group-lg .form-label,
  .input-group.input-group-dynamic.input-group-lg label,
  .input-group.input-group-static.input-group-lg .form-label,
  .input-group.input-group-static.input-group-lg label {
    font-size: 0.975rem;
  }

  label,
.form-label {
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #000;
  margin-left: 0.25rem;
}

.input-group .form-label {
  position: absolute;
  top: 0.6125rem;
  margin-left: 0;
  transition: 0.2s ease all;
}

.form-range {
    width: 100%;
    height: calc(1rem + 4px);
    padding: 0;
    background-color: transparent;
    appearance: none;
  }

  
.form-range:focus {
    outline: 0;
  }
  
  .form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, none;
  }
  
  .form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, none;
  }
  
  .form-range::-moz-focus-outer {
    border: 0;
  }
  
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #220d14;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .form-range::-webkit-slider-thumb {
      transition: none;
    }
  }
  
  .form-range::-webkit-slider-thumb:active {
    background-color: #f9c1d4;
  }
  
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
  }
  
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #220d14;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .form-range::-moz-range-thumb {
      transition: none;
    }
  }
  
  .form-range::-moz-range-thumb:active {
    background-color: #220d14;
  }
  
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
  }
  
  .form-range:disabled {
    pointer-events: none;
  }
  
  .form-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
  }
  
  .form-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
  
  .input-group>.form-control,
  .input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
  }
  
  .input-group>.form-control:focus,
  .input-group>.form-select:focus {
    z-index: 3;
  }
  
  .input-group .btn {
    position: relative;
    z-index: 2;
  }
  
  .input-group .btn:focus {
    z-index: 3;
  }
  
  .input-group-text{
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: #161616;
    text-align: center;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid #d2d6da;
    border-radius: 0.375rem;
  }
 .form-control {
    padding: 0.5rem 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: #161616;
    white-space: nowrap;
    border: 1px solid #0a0a0a;
    border-radius: 0.5rem;
  }
  
  .input-group-lg>.form-control,
  .input-group-lg>.form-select,
  .input-group-lg>.input-group-text,
  .input-group-lg>.btn {
    padding: 0.75rem 0.75rem;
    font-size: 0.875rem;
    border-radius: 0.5rem;
  }
  
  .input-group-sm>.form-control,
  .input-group-sm>.form-select,
  .input-group-sm>.input-group-text,
  .input-group-sm>.btn {
    padding: 0.25rem 0.75rem;
    font-size: 0.75rem;
    border-radius: 0.125rem;
  }
  
  .input-group-lg>.form-select,
  .input-group-sm>.form-select {
    padding-right: 1rem;
  }
  
  .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
  .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .input-group.has-validation> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
  .input-group.has-validation>.dropdown-toggle:nth-last-child(n + 4) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.dark-version .input-group.input-group-dynamic .form-control,
.dark-version .input-group.input-group-static .form-control {
  background-image: linear-gradient(0deg, #220d14 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, rgba(24, 24, 24, 0.6) 1px, rgba(209, 209, 209, 0) 0) !important;
  background-size: 0 100%, 100% 100%;
}

.dark-version .input-group.input-group-dynamic .form-control:focus,
.dark-version .input-group.input-group-static .form-control:focus {
  background-size: 100% 100%, 100% 100%;
}

.dark-version .input-group.input-group-outline .form-control {
  border-color: rgba(36, 36, 36, 0.4) !important;
}

.dark-version .input-group .is-valid,
.dark-version .input-group .is-invalid {
  border-color: rgba(36, 36, 36, 0.4) !important;
}



.input-group {
    border-radius: 0;
  }
  
  .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
  .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3) {
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  
  .input-group.has-validation> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
  .input-group.has-validation>.dropdown-toggle:nth-last-child(n + 4) {
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  
  .input-group,
  .input-group .input-group-text {
    transition: 0.2s ease;
    border: none;
  }
  
  .input-group> :not(:first-child):not(.dropdown-menu) {
    margin-left: 2px;
  }
  
  .input-group label {
    transition: all 0.3s ease;
  }
  
  .input-group.input-group-dynamic .form-control,
  .input-group.input-group-static .form-control {
    background: no-repeat bottom, 50% calc(100% - 1px);
    background-size: 0 100%, 100% 100%;
    transition: 0.2s ease;
  }
  
  .input-group.input-group-dynamic .form-control:not(:first-child),
  .input-group.input-group-static .form-control:not(:first-child) {
    border-left: 0;
    padding-left: 0;
  }
  
  .input-group.input-group-dynamic .form-control:not(:last-child),
  .input-group.input-group-static .form-control:not(:last-child) {
    border-right: 0;
    padding-right: 0;
  }
  
  .input-group.input-group-dynamic .form-control+.input-group-text,
  .input-group.input-group-static .form-control+.input-group-text {
    border-left: 0;
    border-right: 1px solid #d2d6da;
  }
  
  .input-group.input-group-dynamic .form-control,
  .input-group.input-group-dynamic .form-control:focus,
  .input-group.input-group-static .form-control,
  .input-group.input-group-static .form-control:focus {
    background-image: linear-gradient(0deg, #220d14 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #181818 1px, rgba(209, 209, 209, 0) 0);
    border-radius: 0 !important;
  }
  
  .input-group.input-group-dynamic .form-control:focus,
  .input-group.input-group-static .form-control:focus {
    background-size: 100% 100%, 100% 100%;
  }
  
  .input-group.input-group-dynamic .form-control[disabled],
  .input-group.input-group-static .form-control[disabled] {
    cursor: not-allowed;
    background-image: linear-gradient(0deg, #220d14 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #181818 1px, rgba(209, 209, 209, 0) 0) !important;
  }
  
  .input-group.input-group-dynamic .input-group-text,
  .input-group.input-group-static .input-group-text {
    border-right: 0;
  }
  
  .input-group.input-group-dynamic.is-focused .form-label,
  .input-group.input-group-dynamic.is-filled .form-label,
  .input-group.input-group-static.is-focused .form-label,
  .input-group.input-group-static.is-filled .form-label {
    font-size: 0.6875rem !important;
  }
  
  .input-group.input-group-dynamic.is-focused .form-label,
  .input-group.input-group-static.is-focused .form-label {
    top: -0.7rem;
  }
  
  .input-group.input-group-dynamic.is-focused label,
  .input-group.input-group-static.is-focused label {
    color: #220d14;
  }
  
  .input-group.input-group-dynamic.is-focused.is-valid label,
  .input-group.input-group-static.is-focused.is-valid label {
    color: #220d14;
  }
  
  .input-group.input-group-dynamic.is-focused.is-valid .form-control,
  .input-group.input-group-dynamic.is-focused.is-valid .form-control:focus,
  .input-group.input-group-static.is-focused.is-valid .form-control,
  .input-group.input-group-static.is-focused.is-valid .form-control:focus {
    background-image: linear-gradient(0deg, #220d14 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #181818 1px, rgba(209, 209, 209, 0) 0);
    border-radius: 0 !important;
  }
  
  .input-group.input-group-dynamic.is-focused.is-invalid label,
  .input-group.input-group-static.is-focused.is-invalid label {
    color: #220d14;
  }
  
  .input-group.input-group-dynamic.is-focused.is-invalid .form-control,
  .input-group.input-group-dynamic.is-focused.is-invalid .form-control:focus,
  .input-group.input-group-static.is-focused.is-invalid .form-control,
  .input-group.input-group-static.is-focused.is-invalid .form-control:focus {
    background-image: linear-gradient(0deg, #220d14 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #181818 1px, rgba(209, 209, 209, 0) 0);
    border-radius: 0 !important;
  }
  
  .input-group.input-group-dynamic.is-valid .form-control,
  .input-group.input-group-dynamic.is-valid .form-control:focus,
  .input-group.input-group-static.is-valid .form-control,
  .input-group.input-group-static.is-valid .form-control:focus {
    background-image: linear-gradient(0deg, #220d14 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #181818 1px, rgba(209, 209, 209, 0) 0);
    border-radius: 0 !important;
  }
  
  .input-group.input-group-dynamic.is-invalid .form-control,
  .input-group.input-group-dynamic.is-invalid .form-control:focus,
  .input-group.input-group-static.is-invalid .form-control,
  .input-group.input-group-static.is-invalid .form-control:focus {
    background-image: linear-gradient(0deg, #220d14 2px, rgba(156, 39, 176, 0) 0), linear-gradient(0deg, #181818 1px, rgba(209, 209, 209, 0) 0);
    border-radius: 0 !important;
  }
  
  .input-group.input-group-dynamic.is-filled.is-focused .form-label,
  .input-group.input-group-dynamic.is-filled .form-label,
  .input-group.input-group-static.is-filled.is-focused .form-label,
  .input-group.input-group-static.is-filled .form-label {
    top: -1rem;
  }
  
  .input-group.input-group-outline .form-control {
    background: none;
    border: 1px solid #181818;
    border-radius: 0.375rem;
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
    padding: 0.625rem 0.75rem !important;
    line-height: 1.3 !important;
  }
  
  .input-group.input-group-outline .form-control.form-control-lg {
    padding: 0.75rem 0.75rem !important;
  }
  
  .input-group.input-group-outline .form-control.form-control-sm {
    padding: 0.25rem 0.75rem !important;
  }
  
  .input-group.input-group-outline .form-control[disabled] {
    cursor: not-allowed;
    border-style: dashed;
  }
  
  .input-group.input-group-outline .form-label {
    display: flex;
    line-height: 3.925 !important;
    top: -0.375rem;
    margin-bottom: 0;
  }
  
  .input-group.input-group-outline .form-label:before {
    content: "";
    margin-right: 4px;
    border-left: solid 1px transparent;
    border-radius: 4px 0;
  }
  
  .input-group.input-group-outline .form-label:after {
    content: "";
    flex-grow: 1;
    margin-left: 4px;
    border-right: solid 1px transparent;
    border-radius: 0 5px;
  }
  
  .input-group.input-group-outline .form-label:before,
  .input-group.input-group-outline .form-label:after {
    content: "";
    border-top: solid 1px;
    border-top-color: #d2d6da;
    pointer-events: none;
    margin-top: 0.375rem;
    box-sizing: border-box;
    display: block;
    height: 0.5rem;
    width: 0.625rem;
    border-width: 1px 0 0;
    border-color: transparent;
  }
  
  .input-group.input-group-outline.is-focused .form-label+.form-control,
  .input-group.input-group-outline.is-filled .form-label+.form-control {
    border-color: #220d14 !important;
    border-top-color: transparent !important;
    box-shadow: inset 1px 0 #220d14, inset -1px 0 #220d14, inset 0 -1px #220d14;
  }
  
  .input-group.input-group-outline.is-focused .form-label,
  .input-group.input-group-outline.is-filled .form-label {
    width: 100%;
    height: 100%;
    font-size: 0.6875rem !important;
    color: #220d14;
    display: flex;
    line-height: 1.25 !important;
  }
  
  .input-group.input-group-outline.is-focused .form-label:before,
  .input-group.input-group-outline.is-focused .form-label:after,
  .input-group.input-group-outline.is-filled .form-label:before,
  .input-group.input-group-outline.is-filled .form-label:after {
    opacity: 0;
  }
  
  .input-group.input-group-outline.is-focused .form-label:before,
  .input-group.input-group-outline.is-focused .form-label:after,
  .input-group.input-group-outline.is-filled .form-label:before,
  .input-group.input-group-outline.is-filled .form-label:after {
    border-top-color: #220d14;
    box-shadow: inset 0 1px #220d14;
  }
  
  .input-group.input-group-outline.is-valid .form-control {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'%3e%3cpath fill='%2366d432' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 1rem 1rem;
  }
  
  .input-group.input-group-outline.is-valid.is-focused .form-label+.form-control,
  .input-group.input-group-outline.is-valid.is-filled .form-label+.form-control {
    border-color: #4CAF50 !important;
  }
  
  .input-group.input-group-outline.is-valid.is-focused .form-label,
  .input-group.input-group-outline.is-valid.is-filled .form-label {
    color: #4CAF50;
  }
  
  .input-group.input-group-outline.is-valid.is-focused .form-label:before,
  .input-group.input-group-outline.is-valid.is-focused .form-label:after,
  .input-group.input-group-outline.is-valid.is-filled .form-label:before,
  .input-group.input-group-outline.is-valid.is-filled .form-label:after {
    border-top-color: #4CAF50;
    box-shadow: inset 0 1px #4CAF50;
  }
  
  .input-group.input-group-outline.is-invalid .form-control {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd5c70' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fd5c70' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 1rem 1rem;
  }
  
  .input-group.input-group-outline.is-invalid.is-focused .form-label+.form-control,
  .input-group.input-group-outline.is-invalid.is-filled .form-label+.form-control {
    border-color: #220d14 !important;
  }
  
  .input-group.input-group-outline.is-invalid.is-focused .form-label,
  .input-group.input-group-outline.is-invalid.is-filled .form-label {
    color: #220d14;
  }
  
  .input-group.input-group-outline.is-invalid.is-focused .form-label:before,
  .input-group.input-group-outline.is-invalid.is-focused .form-label:after,
  .input-group.input-group-outline.is-invalid.is-filled .form-label:before,
  .input-group.input-group-outline.is-invalid.is-filled .form-label:after {
    border-top-color: #220d14;
    box-shadow: inset 0 1px #220d14;
  }
  
  .input-group.input-group-outline.input-group-sm .form-label,
  .input-group.input-group-outline.input-group-sm label,
  .input-group.input-group-dynamic.input-group-sm .form-label,
  .input-group.input-group-dynamic.input-group-sm label,
  .input-group.input-group-static.input-group-sm .form-label,
  .input-group.input-group-static.input-group-sm label {
    font-size: 0.75rem;
  }
  
  .input-group.input-group-outline.input-group-lg .form-label,
  .input-group.input-group-outline.input-group-lg label,
  .input-group.input-group-dynamic.input-group-lg .form-label,
  .input-group.input-group-dynamic.input-group-lg label,
  .input-group.input-group-static.input-group-lg .form-label,
  .input-group.input-group-static.input-group-lg label {
    font-size: 0.975rem;
  }
  
  .input-group.input-group-static .form-control {
    width: 100%;
  }
  
  .input-group.input-group-static label {
    margin-left: 0;
    margin-bottom: 0;
  }

